import React from "react";
import { withRouter } from "react-router-dom";

import logo_short from "../res/logo_short.png"

import firebase from "../utils/firebaseSetUp";

import HowItWorks from "./modals/HowItWorks";

import DriverData from "./modals/DriverData";

class Navbar extends React.Component {
    _mounted;
    _listenNotifications;

    constructor(props){
        super(props);

        this.state = {
            backstack:null,
            notificationsModal:false,
            howItWorks:false,
            info:false
        }
    }

    componentDidMount(){
        this._mounted = true;


       
    }

    componentWillUnmount(){
        this._mounted = false;
    }


    render(){
        return <nav className="navbar navbar-inverse navbar-expand-md navbar-dark sticky-top">
          {this.state.howItWorks?<HowItWorks
             close={() => {
                 if(this._mounted){
                     this.setState({
                         howItWorks:false
                     })
                 }
             }}/>:null}

          {this.state.info?<DriverData addToast={this.props.addToast} userData={this.props.userData} close={() => {
            if(this._mounted){
              this.setState({
                info:false
              })
            }
          }}/>:null}
  

 
        {this.state.backstack === null?<button className="navbar-toggler mr-auto" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
          <i className="material-icons align-middle">menu</i>
        </button>:<button className="btn btn-sm" onClick={() => {
          if(this._mounted){
             let base = Object.assign({}, this.state.backstack);
      
             base.state = base.to;
      
             this.setState({
               backstack:base
             })
          }
        }}><i className="material-icons align-middle">arrow_back_ios</i></button>}
      
        <a className="navbar-brand" href="#" onClick={() => {
          if(this.state.screen !== 3){
            if(this._mounted){
              this.setState({
                screen:3
              })
            }
          }
        }}><img src={logo_short}/></a>
      
      
      
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item mx-2" onClick={() => {
              if(this._mounted){
                this.setState({
                  howItWorks:true
                })
              }
            }}>
              <a className="nav-link"><b>CÓMO FUNCIONA</b></a>
            </li>

            <li className="nav-item mx-3" onClick={() => {
              window.open(`https://api.whatsapp.com/send?phone=529983047709&text=Hola,%20necesito%20ayuda%20con%20la%20plataforma%20de%20mudatodo,%20mi%20nombre%20es%20${this.props.userData.name}`)
            }}>
              <a className="nav-link"><b>AYUDA</b></a>
            </li>

            <li className="nav-item mx-3" onClick={() => {
              if(this._mounted){
                this.setState({
                  info:true
                })
              }
            }}>
              <a className="nav-link"><b>MI CUENTA</b></a>
            </li>
      
            <li className="nav-item mx-3">
          <button className="btn notification-component" onClick={() => {
             this.props.openModal();

                let count = 0;
      
                for(let i =0; i < this.props.notifications.length; i++){
                  if(this.props.notifications[i].read === false){
                    count++;
                  }
                }
      
                if(count > 0){
                  let batch = firebase.firestore().batch();
      
                  for(let i =0; i < this.props.notifications.length; i++){
                    batch.update(firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("notifications").doc(this.props.notifications[i].id), {
                      read:true
                    })
                  }
      
                  batch.commit()
                  .then(() => {
      
                  })
                  .catch(e => {
                    console.log(e);
                    this.addToast("Algo salió mal")
                  })
                }
              
            
          }}>
                <i className="material-icons align-middle">notifications_none</i>
      
                {(() => {
                  let count = 0;

                  for(let i =0; i < this.props.notifications.length; i++){
                    if(this.props.notifications[i].read === false){
                      count++;
                    }
                  }
      
                  if(count < 1){
                    return 
                  }else {
                   return  <div className="notification-counter"><p>{count}</p></div>
                  }
                })()}
              </button>
          </li>
      
          
      
            <li className="nav-item mx-3">
            <a className="nav-link" onClick={() => {
                    
                    firebase.auth().signOut()
               
                  }}><b>SALIR</b></a>
            </li>
          
          </ul>
        </div>
      </nav>
    }
}

export default withRouter(Navbar);