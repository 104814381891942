import './styles/sass/App.scss';
import {BrowserRouter as Router, Switch, NavLink, Route, Redirect} from "react-router-dom";
import { Position, Toast, Toaster, Classes} from "@blueprintjs/core";
import CircleLoader from "./components/loaders/CircleLoader"
import React,{lazy,Suspense} from "react";
import firebase from "./utils/firebaseSetUp";

import Navbar from "./components/Navbar";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Auth = lazy(() => import("./components/Auth"));
const Messenger = lazy(() => import("./components/Messenger"));
const Contract = lazy(() => import("./components/Contract"));
const PaymentInfo = lazy(() => import("./components/PaymentInfo"));

class App extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      toasts:[],
      auth:false,
      userData:null,

      notifications:[],
      pendingNotifications:false,
      loadMoreNotifications:false,
      loadMorePendingNotifications:false,
      lastDocumentNotifications:null,
      disbleNavbar:false
    }

    this.toaster = {};
        this.refHandlers = {
            toaster:(ref) => {this.toaster = ref},
        }
  }


  loadMoreNotifications = () => {
    if(this._mounted){
      this.setState({
        loadMorePendingNotifications:true
      })
    }

    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("notifications").orderBy("created","desc").startAfter(this.state.lastDocumentNotifications).limit(10).get()
    .then((snap) => {
      let notifications = [];

      snap.forEach(doc => {
        let data =doc.data();
        data.id = doc.id;

        notifications.push(data);
      })

      if(this._mounted){
        this.setState({
          notifications:this.state.notifications.concat(notifications),
          loadMoreNotifications:snap.docs.length < 10?false:true,
          lastDocumentNotifications:snap.docs[snap.docs.length - 1],
          loadMorePendingNotifications:false
        })
      }
    })
    .catch(e => {
      console.log(e);
      this.addToast("Algo salió mal");

      if(this._mounted){
        this.setState({
          loadMorePendingNotifications:false
        })
      }
    })
  }

  componentDidMount(){
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {

        firebase.firestore().collection("users").doc(user.uid).get()
        .then(doc => {
          if(doc.exists){
          if(doc.data().userType === 1){
      
              let data = doc.data();
              data.id = doc.id
              this.setState({
                userData:data,
                auth:true
              })

              this._listenNotifications = firebase.firestore().collection("users").doc(user.uid).collection("notifications").orderBy("created","desc").limit(10).onSnapshot(snap => {
                let notifications = [];
      
                let unread = 0;
      
                snap.forEach(doc => {
                  let data = doc.data();
                  data.id = doc.id;
      
      
                  notifications.push(data);
                  
      
                  if(doc.data().read === false){
                     unread++;
                  }
                })

                
      
             
                  this.setState({
                    notifications:notifications,
                    loadMoreNotifications:snap.docs.length < 10?false:true,
                    lastDocumentNotifications:snap.docs[snap.docs.length -1],
                    pendingNotifications:false
                  }, () => {
                    if(this.state.screen === 7){
                      let batch = firebase.firestore().batch();
      
                      for(let i =0; i < this.state.notifications.length; i++){
                        if(this.state.notifications[i].chatID !== undefined && this.state.notifications[i].read === false){
                          batch.update(firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("notifications").doc(this.state.notifications[i].id), {
                            read:true
                          })
                        }
                      }
      
                      batch.commit()
                      .then(() => {
      
                      })
                      .catch(e => {
                        console.log(e);
                        this.addToast("Algo salió mal")
                      })
                    }
                  })
                
              }, (e) => {
                console.log(e);
                this.props.addToast("Algo salió mal")
      
                if(this._mounted){
                  this.setState({
                    pendingNotifications:false
                  })
                }
              })
          }else {
            this.addToast("Este usuario no es un driver")
            firebase.auth().signOut()
          }

        }else {
          this.addToast("El usuario no existe");
          firebase.auth().signOut()
        }
       })

      }else {
        this.setState({
          auth:false,
          userData:null
        })
      }
    })
  }

  addToast = (message) => {
    this.toaster.show({ message: message});
}

  render(){
    return <div className="app">
      <Modal open={this.state.notificationsModal} onClose={() => {
      
          this.setState({
            notificationsModal:false
          })
        
      }} center>

        <h4 className="text-center mt-5 mb-2">NOTIFICACIONES</h4>
        {this.state.pendingNotifications?<div className="mt-4 flex-center"><div className="spinner-border"></div></div>:
        this.state.notifications.length < 1?<p className="text-center mt-4">No hay notificationes</p>:
        this.state.notifications.map((e,i) => {
          return <div className="notification-element mt-2" key={i}>
             <h6>{e.title}</h6>
             <p className="mt-2">{e.content}</p>

             <p className="mt-2"><small>{e.created.toDate().getDate()}/{e.created.toDate().getMonth() + 1}/{e.created.toDate().getFullYear()} {e.created.toDate().getHours()}:{e.created.toDate().getMinutes()}</small></p>
          </div>
        })}

        {this.state.loadMorePendingNotifications?<div className="text-center mt-3"><div className="spinner-border"></div></div>:this.state.loadMoreNotifications === true?<div className="mt-3 flex-center"><button className="btn" onClick={() => {
          this.loadMoreNotifications();
        }}>
          <span>Cargar más</span>
          </button></div>:null}
      </Modal>
      <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={this.refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {this.state.toasts.map(toast => <Toast action={{onClick:() => {}, text:"Resend"}} {...toast} />)}
           </Toaster>


           <Suspense fallback={<CircleLoader/>}>
             <Router>
               {this.state.auth && this.state.disbleNavbar === false?<Navbar userData={this.state.userData} openModal={() => {
                 this.setState({
                   notificationsModal:true
                 })
               }} notifications={this.state.notifications} addToast={this.addToast}/>:null}
               <Switch>
                  <Route path="/" exact={true} render={() => this.state.auth?<Messenger userData={this.state.userData} addToast={this.addToast}/>:<Redirect to="/autenticación"/>}/>
                  <Route path="/autenticación" exact={true} render={() => this.state.auth?<Redirect to="/"/>:<Auth addToast={this.addToast}/>}/>
                  <Route path="/contrato/:proposalID" exact={true} render={({match}) => <Contract disableNavbar={() => {
                    this.setState({
                      disbleNavbar:true
                    })
                  }} match={match} addToast={this.addToast}/>}/>
                  <Route path="/contrato/:proposalID/:insurance" exact={true} render={({match}) => <PaymentInfo match={match} disableNavbar={() => {
                    this.setState({
                      disbleNavbar:true
                    })
                  }}/> }/>
               </Switch>
             </Router>
          </Suspense>
    </div>
  }
}

export default App;
