import React from "react";

class HowItWorks extends React.Component {
    _mounted;

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;

        window.document.body.style.overflow = "hidden";
    }

    componentWillUnmount(){
        this._mounted = false;

        window.document.body.style.overflow = "visible";
    }

    render(){
        return <div className="modal-wrapper fade-in">
            <button className="btn btn-close-modal" onClick={() => {
                this.props.close()
            }}>
                <i className="material-icons align-middle">clear</i>
            </button>
            <div className="modal-content">
                <div>
                    <h2 className="text-center mt-4">COMO FUNCIONA</h2>

                    <p className="mt-4">
                    Mudatodo es una plataforma web y móvil diseñada para que los transportistas contacten a clientes que requieren de 
                    servicio de mudanza o flete <br/><br/>

                    <b>Para adjudicarse a los servicios debe seguir los siguientes pasos</b>  <br/><br/>

                    1.- Contar con una cuenta activa y acceder a través de la página Web o la App <br/> <br/>

                    2.- Seleccionar una solicitud publicada. (Todas las solicitudes se colocan en tiempo real) <br/> <br/>

                    3.- Realizar una oferta (Si consideras que no hay información suficiente para realizar una oferta, puedes hacer un 
                    cálculo aproximado y posteriormente puedes cambiar la oferta ya que hayas hablado con el cliente a través del chat) <br/> <br/>

                    4.- Utiliza el chat únicamente para preguntas detalles del servicio y así hacer una oferta (No proporciones ni solicites teléfono, 
                    Whatsapp, o cualquier otro medio de contacto)<br/><br/>

                    5.- Una vez que el cliente haya aceptado tu oferta y haya aceptado el pago por concepto de reservación, Mudatodo 
                    te proporcionará una forma de orden con los datos del cliente con la información necesaria para que contactes al cliente 
                    y así proceder a realizar el servicio  <br/><br/>

                    6.- El precio que ofertaste, es la cantidad a cobrar al cliente. Mudatodo cobra la comisión calculada al momento 
                    que realizas la oferta por concepto de reservación <br/> <br/>


                    <b style={{
                        color:"#f9ac19"
                    }}>IMPORTANTE</b>

                    <br/><br/>

                    Compañero tranportista, para usar la plataforma hay algunas reglas que debes seguir. En caso de violación de alguna de ellas,
                     será motivo para la cancelación de la cuenta, y el Titular, empresa o representante, no podrá abrir una nueva.<br/><br/>

                     <b>REGLAS DE USO</b><br/><br/>

                     Será motivo de cancelación de la cuenta:<br/>
                     1.- Proporcionar información falsa al momento del registro. <br/>
                     2.- Proporcionar información falsa a los clientes. <br/>
                     3.- Incumplimiento de acuerdos para la realización de algún servicio. <br/>
                     4.- Proporcionar datos de contacto, como teléfono, email, Whatsapp, Telegram o cualquier otro medio 
                     a través del chat antes de que el cliente haya concretado la reservación del servicio. <br/>
                     5.- Alguna otra falta no contemplada en las anteriores, por lo que nos reservamos el derecho de admisión y dar de baja 
                     a tranportistas cuyo comportamiento o práctivas sean nocivas a los usuarios, transportistas y la plataforma.
                    </p>
                </div>
            </div>
        </div>
    }
}


export default HowItWorks;