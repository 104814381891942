import React from "react";
import { withRouter } from "react-router";

import firebase from "../../utils/firebaseSetUp";

import KeywordsGeneration from "../../utils/KeywordsGeneration";

import profile_img from "../../res/placeholder_profile.png"

class DriverData extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        this.state = {
            isLoading:false,
            input:{
                name:"",
                address:"",
                company:"",
                colony:"",
                rfc:"",
                locality:"",
                email:"",
                state:"",
                phone:"",
                mobilePhone:"",
                interbancaryPass:"",
                bank:"",
                password:"",
                cPassword:"",
            }
        }
    }

    componentDidMount(){
        this._mounted = true;

        

        if(this._mounted){
            this.setState({
                input:this.props.userData
            })
        }

        
    }

    componentWillUnmount(){
        this._mounted = false;
    }


    updateUser = () => {

        let flag =0;
        let messages = [];

        if(this.state.input.name.length  < 1){
            flag =1;
            messages.push("Debe colocar el nombre")
        }

        if(this.state.input.address.length < 1){
            flag = 1;
            messages.push("Debe colocar el domicilio")
        }

        if(this.state.input.company.length < 1){
            flag = 1;
            messages.push("Debe colocar el nombre de la empresa")
        }

        if(this.state.input.colony.length < 1){
            flag = 1;
            messages.push("Debe colocar la colonia");
        }

        if(this.state.input.locality.length < 1){
            flag = 1;
            messages.push("Debe colocar la Localidad")
        }

        if(!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.input.email))){
            flag = 1;
            messages.push("Email Inválido")
        }

        if(!(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.input.phone))){
            flag = 1;
            messages.push("Teléfono Inválido")
        }

        

        if(this.state.input.password !== this.state.input.cPassword){
            flag = 1;
            messages.push("Las contraseñas deben coincidir");
        }

        if(flag === 0){
            if(this._mounted){
                this.setState({
                  isLoading:true
                })
            }

        firebase.firestore().collection("users").doc(this.props.userData.id).update({
            name:this.state.input.name !== undefined?this.state.input.name:"",
            phone:this.state.input.phone !== undefined?this.state.input.phone:"",
            mobilePhone:this.state.input.mobilePhone !== undefined? this.state.input.mobilePhone:"",
            address:this.state.input.address !== undefined?this.state.input.address:"",
            company:this.state.input.company !== undefined?this.state.input.company:"",
            colony:this.state.input.colony !== undefined?this.state.input.colony:"",
            locality:this.state.input.locality !== undefined?this.state.input.locality:"",
            state:this.state.input.state !== undefined?this.state.input.state:"",
            interbancaryPass:this.state.input.interbancaryPass !== undefined?this.state.input.interbancaryPass:"",
            bank:this.state.input.bank !== undefined?this.state.input.bank:"",
            rfc:this.state.input.rfc !== undefined?this.state.input.rfc:"",
            updated:firebase.firestore.Timestamp.now(),
            keywords:KeywordsGeneration.generateKeywords(this.state.input.name)
            .concat(KeywordsGeneration.generateKeywords(this.state.input.email))
            .concat(KeywordsGeneration.generateKeywords(this.state.input.phone))
        })
        .then(() => {

            let base = Object.assign({}, this.state.input);
base.name = "";
base.address = "";
base.company = "";
base.colony = "";
base.rfc = "";
base.locality = "";
base.email = "";
base.state = "";
base.phone = "";
base.mobilePhone = "";
base.interbancaryPass = "";
base.bank = "";
base.password = "";
base.cPassword= "";
base.file = null;
if(this._mounted){
    this.setState({
      isLoading:false
    })
}

            this.props.addToast("Empresa Actualizada!");
        })
        .catch(e => {
            console.log(e);
            if(this._mounted){
                this.setState({
                  isLoading:false
                })
            }
        this.props.addToast("Algo salió mal");
        })

    }else {
        for(let i =0; i<messages.length; i++){
            this.props.addToast(messages[i])
        }
    }
    }

    render(){
        return <div className="modal-wrapper">
            <button className="btn btn-close-modal" onClick={() => {
                this.props.close();
            }}>
                <i className="material-icons align-middle">clear</i>
            </button>

            <div className="modal-content">

                <div className="flex-center mt-4">
                    <img src={this.props.userData.photoURL !== "" && this.props.userData.photoURL !== undefined?this.props.userData.photoURL:profile_img} width="80px" height="80px" style={{borderRadius:"50%"}} />
                </div>
            <div className="row p-0 m-0 mt-4">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input className="form-control" placeholder="Nombre" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.name = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }} value={this.state.input.name} type="text"/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="form-group">
                            <input className="form-control" placeholder="Domicilio" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.address = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  value={this.state.input.address} type="text"/>
                        </div>
                    </div>
                </div>

                <div className="row p-0 m-0 mt-2">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input className="form-control" placeholder="Empresa" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.company = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  value={this.state.input.company} type="text"/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="form-group">
                            <input className="form-control" placeholder="Colonia" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.colony = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  value={this.state.input.colony} type="text"/>
                        </div>
                    </div>
                </div>

                <div className="row p-0 m-0 mt-2">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input className="form-control" placeholder="RFC" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.rfc = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  type="text" value={this.state.input.rfc}/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="form-group">
                            <input className="form-control" placeholder="Localidad" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.locality = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  type="text" value={this.state.input.locality}/>
                        </div>
                    </div>
                </div>

                <div className="row p-0 m-0 mt-2">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input className="form-control" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.email = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  disabled={true} placeholder="Email" type="text" value={this.state.input.email}/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="form-group">
                            <input className="form-control" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.state = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  placeholder="Estado" type="text" value={this.state.input.state}/>
                        </div>
                    </div>
                </div>

                <div className="row p-0 m-0 mt-2">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input className="form-control" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.phone = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  placeholder="Teléfono" type="text" value={this.state.input.phone} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="form-group">
                            <input className="form-control" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.mobilePhone = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  placeholder="Celular" type="text" value={this.state.input.mobilePhone}/>
                        </div>
                    </div>
                </div>

                <div className="row p-0 m-0 mt-2">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input className="form-control" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.interbancaryPass = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  placeholder="Clabe Interbancaria" value={this.state.input.interbancaryPass} type="text"/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="form-group">
                            <input className="form-control" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.bank = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  placeholder="Banco" value={this.state.input.bank} type="text"/>
                        </div>
                    </div>

                   
                </div>

                {this.state.edit === false?<div className="row p-0 m-0 mt-2">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input className="form-control" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.password = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  placeholder="Password" value={this.state.input.password} type="password"/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="form-group">
                            <input className="form-control" onChange={(e) => {
                               let base = Object.assign({}, this.state.input);
                               base.cPassword = e.currentTarget.value;

                               if(this._mounted){
                                   this.setState({
                                       input:base
                                   })
                               }
                            }}  placeholder="Confirmar Password" value={this.state.input.cPassword} type="password"/>
                        </div>
                    </div>

                   
                </div>:null}

                <div className="mt-4 flex-center">
                   {this.state.isLoading?<div className="spinner-border"></div>:<button className=" btn btn-custom-1" onClick={() => {
                        this.updateUser();
                    }}>
                        <span>Guardar</span>
                    </button>}
                </div>
            </div>
        </div>
    }
}

export default withRouter(DriverData);